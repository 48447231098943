"use client";

import React, {
	createContext,
	ReactNode,
	useContext,
} from "react";

// Define the type for backend variants
export interface BackendConstants {
	shipping: {
		delay: {
			value: number;
			unit: string;
		};
	};
	return: {
		delay: {
			value: number;
			unit: string;
		};
	};
}

interface HumanReadableBackendConstants {
	shipping: {
		delay_in_days: number;
		delay_in_hours: number;
	};
	return: {
		delay_in_days: number;
		delay_in_hours: number;
	};
}

// Create a context with a default value
const BackendConstantsContext =
	createContext<
		| HumanReadableBackendConstants
		| undefined
	>(undefined);

// Create a provider component
interface BackendConstantsProviderProps {
	backend_constants: BackendConstants;
	children: ReactNode;
}

export const BackendConstantsProvider: React.FC<
	BackendConstantsProviderProps
> = ({
	backend_constants,
	children,
}) => {
	const humanReadableBackendConstants: HumanReadableBackendConstants =
		{
			shipping: {
				delay_in_days: Math.round(
					backend_constants.shipping
						.delay.value /
						60 /
						60 /
						24
				),
				delay_in_hours: Math.round(
					backend_constants.shipping
						.delay.value /
						60 /
						60
				),
			},
			return: {
				delay_in_days: Math.round(
					backend_constants.return.delay
						.value /
						60 /
						60 /
						24
				),
				delay_in_hours: Math.round(
					backend_constants.return.delay
						.value /
						60 /
						60
				),
			},
		};

	return (
		<BackendConstantsContext.Provider
			value={
				humanReadableBackendConstants
			}>
			{children}
		</BackendConstantsContext.Provider>
	);
};

// Custom hook to use the BackendConstantsContext
export const useBackendConstants =
	(): HumanReadableBackendConstants => {
		const context = useContext(
			BackendConstantsContext
		);
		if (context === undefined) {
			throw new Error(
				"useBackendConstants must be used within a BackendConstantsProvider"
			);
		}
		return context;
	};
